import React, { useState, useEffect } from "react";

import { githubAuthApiRef, useApi } from "@backstage/core-plugin-api";

import { Typography, Button, CardContent, CardActions, Grid, Box, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Octokit } from '@octokit/rest';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { HomePageInfoCard } from "../styled";

type GithubPullRequest = {
  id: number;
  title: string;
  htmlUrl: string;
  createdAt: string;
  user: {
    login: string;
  };
};

const Subtitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontSize: '11px',
  fontWeight: 'bold',
}));

const DescriptionBody = styled(Typography)({
  fontSize: '13px',
  paddingBottom: '10px',
});

const PullRequestItem = styled(Typography)({
  fontSize: '13px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PullRequestName = styled('span')({
  margin: '0 10px',
  flex: '1 1 auto',
  textAlign: 'left',
  wordBreak: 'break-word',
});

const PullRequestDate = styled('span')({
  fontWeight: 600,
  fontSize: '12px'
})

export const CollaborateCard = () => {
  const [openPullRequests, setOpenPullRequests] = useState<GithubPullRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const auth = useApi(githubAuthApiRef);
  const owner = 'innersource-nn';

  const fetchPullRequestsData = async () => {
    try {
      setLoading(true);
      const token = await auth.getAccessToken(['repo']);
      const octokit = new Octokit({ auth: token });

      const searchResponse = await octokit.search.issuesAndPullRequests({
        per_page: 5,
        sort: 'created',
        q: `org:${owner}+is:open+type:pr`,
      });

      const fetchedPullRequests: GithubPullRequest[] =
        searchResponse.data.items.map((pullRequest) => ({
          id: pullRequest.id,
          title: pullRequest.title,
          htmlUrl: pullRequest.html_url,
          createdAt: pullRequest.created_at,
          user: {
            login: pullRequest.user?.login || 'N/A',
          },
      }));

      setOpenPullRequests(fetchedPullRequests);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pull requests:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPullRequestsData();
  }, [auth]);

  return (
    <HomePageInfoCard title="Collaborate">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Subtitle>description</Subtitle>
          </Grid>
          <Grid item>
            <DescriptionBody>
              Check these latest open pull requests from the <b>innersource-nn</b> repositories in <b>GitHub</b>
            </DescriptionBody>
          </Grid>
          <Grid item>
            <Subtitle>recently created pull requests</Subtitle>
          </Grid>
          {loading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!openPullRequests.length ? (
                  openPullRequests.map((pullRequest) => (
                    <Grid item key={pullRequest.id}>
                      <PullRequestItem>
                        <PullRequestDate>{new Date(pullRequest.createdAt).toLocaleDateString()}</PullRequestDate>
                        <PullRequestName>"{pullRequest.title}" by: <b>{pullRequest.user.login}</b></PullRequestName>
                        <Button
                          component="a"
                          href={pullRequest.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{minWidth: 'fit-content'}}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </PullRequestItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <PullRequestItem>No open pull requests found</PullRequestItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={`https://github.com/${owner}`}
            startIcon={<GitHubIcon />}
          >
            <b>novo nordisk innersource</b>
          </Button>
        </Box>
      </CardActions>
    </HomePageInfoCard>
  );
};
