import { InfoCard } from "@backstage/core-components";
import { styled } from '@mui/material/styles';

export const HomePageInfoCard = styled(InfoCard)(({ theme }) => ({
  width: '32%',
  [theme.breakpoints.down('md')] : {
    width: '100%',
  },
  marginTop: '20px',
  height: 'fit-content'
}));
