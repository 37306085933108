import React, { useState, useEffect } from "react";

import { githubAuthApiRef, useApi } from "@backstage/core-plugin-api";

import { Typography, Button, CardContent, CardActions, Grid, Box, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Octokit } from '@octokit/rest';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { HomePageInfoCard } from "../styled";

type GithubRelease = {
  id: number;
  name: string;
  tagName: string;
  publishedAt: string;
  htmlUrl: string;
};

const Subtitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontSize: '11px',
  fontWeight: 'bold',
}));

const DescriptionBody = styled(Typography)({
  fontSize: '13px',
  paddingBottom: '10px',
});

const ReleaseItem = styled(Typography)({
  fontSize: '13px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ReleaseName = styled('span')({
  margin: '0 10px',
  flex: '1 1 auto',
  textAlign: 'left',
  wordBreak: 'break-word',
});

const ReleaseDate = styled('span')({
  fontWeight: 600,
  fontSize: '12px'
})

export const EngageCard = () => {
  const [releases, setReleases] = useState<GithubRelease[]>([]);
  const [loading, setLoading] = useState(true);
  const auth = useApi(githubAuthApiRef);
  const owner = 'innersource-nn';
  const repo = 'midgard';

  const fetchReleaseData = async () => {
    try {
      setLoading(true);
      const token = await auth.getAccessToken(['repo']);
      const octokit = new Octokit({ auth: token });

      const response = await octokit.repos.listReleases({
        owner,
        repo,
        per_page: 5,
      });

      const fetchedReleases: GithubRelease[] = response.data.map((release) => ({
        id: release.id,
        name: release.name ?? 'N/A',
        tagName: release.tag_name,
        publishedAt: release.published_at ?? 'N/A',
        htmlUrl: release.html_url,
      }));

      setReleases(fetchedReleases);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching releases:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReleaseData();
  }, [auth]);

  return (
    <HomePageInfoCard title="Engage">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Subtitle>description</Subtitle>
          </Grid>
          <Grid item>
            <DescriptionBody>Here you can find the latest releases from the <b>Developer Portal</b></DescriptionBody>
          </Grid>
          <Grid item>
            <Subtitle>recent releases</Subtitle>
          </Grid>
          {loading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!releases.length ? (
                  releases.map((release) => (
                    <Grid item key={release.id}>
                      <ReleaseItem>
                        <ReleaseDate>{new Date(release.publishedAt).toLocaleDateString()}</ReleaseDate>
                        <ReleaseName>{release.name}</ReleaseName>
                        <Button
                          component="a"
                          href={release.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{minWidth: 'fit-content'}}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </ReleaseItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <ReleaseItem>No releases found</ReleaseItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href="https://github.com/innersource-nn/midgard/issues"
            startIcon={<GitHubIcon />}
          >
            <b>request feature or leave feedback</b>
          </Button>
        </Box>
      </CardActions>
    </HomePageInfoCard>
  );
};
